<template>
  <!-- <h1>ListGroup</h1> -->
  <v-list-group
    class="drawer_list_group"
    :group="group"
    :prepend-icon="item.icon || ''"
    eager
    v-bind="$attrs"
    @click.native.stop="checkMini"
    v-model="item.active"
  >
    <template v-slot:activator>
      <v-list-item-icon
        v-if="!item.icon && !item.avatar"
        class="text-caption text-uppercase text-center my-2 align-self-center"
        style="margin-top: 14px"
      >
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.title">
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.items">
      <default-list-group
        v-if="child.items"
        :key="`sub-group-${i}`"
        :item="child"
      />

      <default-list-item
        v-if="!child.items"
        :key="`child-${i}`"
        :item="child"
        @click.stop="mini = true"
      />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import { sync } from "vuex-pathify";

export default {
  name: "DefaultListGroup",

  components: {
    DefaultListItem: () => import("./ListItem"),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...sync("app", ["mini"]),
    group() {
      return this.genGroup(this.item.items);
    },
    // title() {
    //   const matches = this.item.title.match(/\b(\w)/g);

    //   return matches.join("");
    // },
  },

  methods: {
    genGroup(items) {
      // console.log({ items });
      return items
        .reduce((acc, cur) => {
          // console.log({ acc });
          // console.log({ cur });
          if (!cur.to) return acc;

          acc.push(cur.items ? this.genGroup(cur.items) : cur.to.slice(1, -1));

          return acc;
        }, [])
        .join("|");
    },
    checkMini() {
      if (this.mini) this.mini = false;
    },
  },
};
</script>
<style scoped lang="scss">
.drawer_list_group {
  &:deep(.v-list-group__header) {
    color: #fff !important;
  }
  &:deep(.v-list-item__icon i) {
    color: #fff9 !important;
  }
}
</style>
